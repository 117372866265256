*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
@font-face {
    font-family: 'Kajiro';
    src: url(./Kajiro.woff2);
}
html,body {
    width: 100%;
    height: 100%;
}

#main{
    width:100% ;
    height: 100vh;
    
}
#back{
    width:100% ;
    height: 100vh;
    
}
#back img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#top{
    pointer-events: none;
    position: absolute;
    top:0;
    left:0;
    width:100% ;
    height: 100vh;
    background-color:transparent;
}

/* Add this to your CSS */
#top input{
    pointer-events: all;
}
#workingarea{
    position: relative;
    margin:0px auto;
    width:80%;
    max-width: 1920px;
    height: 100vh;
}
#nav{
    pointer-events: all;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    height: 200px;
    /* padding: 6px 0; */
}
#nav img{
    height:250px;
}
#nav a{
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13px;
    font-weight: 600;
}
#nleft , #nright{
    display: flex;
    align-items: center;
    gap: 40px;
}
#hero{
    display: flex;
    position:absolute;
    top: 60%;
    left: 0;
    transform: translate(0%,-50%);
    justify-content: space-between;
    width: 100%;
    
}
#heroleft{
    min-width: 50%;
    
    
}
#heroleft .elem h1{
    position:relative;
    font-family: 'Kajiro';
    color: white;
    font-weight: 100;
    font-size:10vw ;
}
#heroleft .elem{
    position: relative;
    overflow: hidden;
    height: 10vw;
    
}
#heroleft button{
    pointer-events: all;
    font-family: "kajiro";
    font-size: 3vw;
    padding: 12px 35px;
}
#heroright{
    width: 20%;
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
#heroright #imagediv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* background-image: url(./qr.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;  */
  }
#heroright p{
    color: white;
    font-size: 12px;
    margin-bottom: 30px;
    text-align: right;
}
#heroright #para{
    overflow: hidden;
    width: 100%;
    height:4vw;
    background-color: blue;
}